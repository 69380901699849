<template>
	<div class="h-100">
		<div class="loader" v-if="loading">
			<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
		</div>
		<div class="h-100" v-if="!token">
			<div class="wrapper__registration" v-if="!password_show">
				<header class="header header--empty">
					<router-link :to="`/?step=${step}`" class="header__back header__back--register"></router-link>
				</header>
				<h1 class="registration__title">Давай знакомиться!</h1>
				<form class="registration__form">
					<input type="text" name="first-name" placeholder="имя" v-model="name">
					<input type="text" name="last-name" placeholder="фамилия" v-model="lastname">
					<input type="email" name="mail" placeholder="e-mail" v-model="email" inputmode="email">
					<input type="tel" name="phone" placeholder="телефон" v-model="phone" inputmode="tel">
					<p class="registration__error" v-if="validation_error">{{validation_error.error}}</p>
					<p class="registration__error" v-if="error">Необходимо заполнить все поля</p>
					<a href="javascript:void(0)" class="button button__registration" @click="checkUser">Продолжить</a>
				</form>
			</div>
			<div class="wrapper__registration" v-else>
				<header class="header header--empty">
					<a href="javascript:void(0)" class="header__back header__back--register" @click="password_show = false"></a>
				</header>
				<h1 class="registration__title">Создание пароля</h1>
				<form class="registration__form">
					<input type="password" name="password" placeholder="пароль" v-model="password">
					<input type="password" name="password-repeat" placeholder="подтверждение пароля" v-model="password_2">
					<p class="registration__error" v-if="passwordError">{{passwordError}}</p>
					<a href="javascript:void(0)" class="button button__registration" @click="register">Готово</a>
				</form>
				<button class="button__continue button__continue-black button__continue-black--reg" @click="registerWithoutPassword">Пропустить</button>
			</div>
		</div>
		<div class="loader" v-else>
			<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Vuelidate from 'vuelidate'
	import { required, sameAs, minLength } from 'vuelidate/lib/validators'
	import moment from 'moment';

	Vue.use(Vuelidate)
	export default {
		name: 'register',
		validations: {
			name: {
				required,
			},
			lastname: {
				required,
			},
			email: {
				required,
			},
			phone: {
				required,
			},
			password: {
				required,
				minLength: minLength(8)
			},
			password_2: {
				sameAsPassword: sameAs('password')
			},
		},
		data() {
			return {
				password_show: false,
				name: null,
				lastname: null,
				email: null,
				phone: null,
				error: false,
				password: null,
				password_2: null,
				passwordError: null,
				loading: false,
			}
		},
		computed: {
			values() {
				return this.$store.state.values;
			},
			validation_error() {
				return this.$store.state.validation_error;
			},
			validation() {
				return this.$store.state.validation;
			},
			step() {
				return this.$store.state.step;
			},
			token() {
				return this.$store.state.token;
			}
		},
		mounted() {
			if (this.token && this.token.length > 5) {
				this.sendQuiz();
			}
		},
		methods: {
			checkUser() {
				this.$v.name.$touch()
				this.$v.lastname.$touch()
				this.$v.email.$touch()
				this.$v.phone.$touch()
				if (this.$v.name.$invalid || this.$v.lastname.$invalid || this.$v.email.$invalid || this.$v.phone.$invalid) {
					this.error = true;
				} else {
					this.error = false;
					let fields = {
						name: this.name,
						surname: this.lastname,
						email: this.email,
						phone: this.phone,
					}
					this.loading = true;
					this.$store.dispatch('checkUser', fields).then(() => {
						if (this.validation && this.validation.success == true) {
							this.password_show = true;
						}
						this.loading = false;
					}).catch(() => {
						this.loading = false;
					})
				}
			},
			register() {
				this.passwordError = null;
				this.$v.password.$touch()
				this.$v.password_2.$touch()
				if (this.$v.password.$invalid || this.$v.password_2.$invalid) {
					this.passwordError = "Минимальная длина пароля: 8 символов. Пароли должны совпадать";
				} else {
					let fields = {
						name: this.name,
						surname: this.lastname,
						email: this.email,
						phone: this.phone,
						password: this.password,
					}
					this.loading = true;
					this.$store.dispatch('registerUser', fields).then(() => {
						this.sendQuiz();
						this.loading = false;
					}).catch(() => {
						this.loading = false;
					})
				}
			},
			registerWithoutPassword() {
				let fields = {
					name: this.name,
					surname: this.lastname,
					email: this.email,
					phone: this.phone,
				}
				this.loading = true;
				this.$store.dispatch('registerUser', fields).then(() => {
					this.sendQuiz();
						this.loading = false;
				}).catch(() => {
					this.loading = false;
				})
			},
			sendQuiz() {
				if (this.values.filter(v => v.id == 4).length > 0) {
					let quizData = {
						gender: null,
						age: null,
						weight: null,
						height: null,
						experience: null,
						goal: null,
						goal_distantion: null,
						six_month_experience: null,
						six_month_experience_run: null,
						six_month_experience_distance: null,
						goal_result: null,
						race_planned_date: null,
					};
					if (this.values.filter(v => v.id == 0).length > 0) { quizData.gender = this.values.filter(v => v.id == 0)[0].answerText; }
					if (this.values.filter(v => v.id == 1).length > 0) { quizData.age = this.values.filter(v => v.id == 1)[0].answerText; }
					if (this.values.filter(v => v.id == 2).length > 0) { quizData.height = this.values.filter(v => v.id == 2)[0].answerText; }
					if (this.values.filter(v => v.id == 3).length > 0) { quizData.weight = this.values.filter(v => v.id == 3)[0].answerText; }
					if (this.values.filter(v => v.id == 4).length > 0) { quizData.experience = this.values.filter(v => v.id == 4)[0].answerText; }
					if (this.values.filter(v => v.id == 5).length > 0) { quizData.goal = this.values.filter(v => v.id == 5)[0].answerText; }
					if (this.values.filter(v => v.id == 6).length > 0) { quizData.goal_distantion = this.values.filter(v => v.id == 6)[0].answerText; }
					if (this.values.filter(v => v.id == 7).length > 0) { quizData.six_month_experience = this.values.filter(v => v.id == 7)[0].answerText; }
					if (this.values.filter(v => v.id == 6.1).length > 0) { quizData.six_month_experience_run = this.values.filter(v => v.id == 6.1)[0].answerText; }
					if (this.values.filter(v => v.id == 6.2).length > 0) { quizData.six_month_experience_distance = this.values.filter(v => v.id == 6.2)[0].answerText; }
					if (this.values.filter(v => v.id == 6.3).length > 0) { quizData.goal_result = this.values.filter(v => v.id == 6.3)[0].answerText; }
					if (this.values.filter(v => v.id == 9).length > 0) { quizData.race_planned_date = this.values.filter(v => v.id == 9)[0].answerText; }
					let code = 'start-running';
					if (this.values.filter(v => v.id == 5).length > 0) {
						if (this.values.filter(v => v.id == 4)[0].answer > 3 && this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 0) {
							code = 'keep-running';
						}
					}
					if (this.values.filter(v => v.id == 5).length > 0 && this.values.filter(v => v.id == 6).length > 0) {
						if (this.values.filter(v => v.id == 4)[0].answer > 3 && this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && this.values.filter(v => v.id == 6)[0].answer == 1) {
							code = 'm1hm';
						}
					}
					if (this.values.filter(v => v.id == 5).length > 0 && this.values.filter(v => v.id == 6).length > 0) {
						if (this.values.filter(v => v.id == 4)[0].answer > 3 && this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && this.values.filter(v => v.id == 6)[0].answer == 2) {
							code = 'm1m';
						}
					}
					if (this.values.filter(v => v.id == 5).length > 0 && this.values.filter(v => v.id == 6).length > 0 && this.values.filter(v => v.id == 6.2).length > 0 && this.values.filter(v => v.id == 8).length > 0 && this.values.filter(v => v.id == 7).length > 0) {
						if (this.values.filter(v => v.id == 4)[0].answer > 3 && this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && this.values.filter(v => v.id == 6)[0].answer == 2 && this.values.filter(v => v.id == 7)[0].answer == 3 && this.values.filter(v => v.id == 8)[0].answer == 1 &&  this.values.filter(v => v.id == 6.2)[0].answer.filter(a => a.id == 4).length == 1) {
							code = 'mfm';
						}
					}
					let date = null;
					if (this.values.filter(v => v.id == 9).length > 0) {
						date = moment(this.values.filter(v => v.id == 9)[0].answer, 'DD.MM.YYYY').format('YYYY-MM-DD')
					}
					let quiz = {
						"quiz": {
							"recommended_program": {
								"code": code,
								"run_at": date,
							},
							"payload": {
								"gender": quizData.gender,
								"age": quizData.age,
								"weight": quizData.weight,
								"height": quizData.height,
								"experience": quizData.experience,
								"goal": quizData.goal,
								"goal_distantion": quizData.goal_distantion,
								"six_month_experience": quizData.six_month_experience,
								"six_month_experience_run": quizData.six_month_experience_run,
								"six_month_experience_distance": quizData.six_month_experience_distance,
								"goal_result": quizData.goal_result,
								"race_planned_date": quizData.race_planned_date,
							}
						}
					}
					this.$store.dispatch('sendQuiz', quiz).then(() => {
						if (this.values.filter(v => v.id == 4).length > 0) {
							this.$router.push('/finish-register')
						} else {
							document.location.href = `app://form-finish?token=${this.token}`;
						}
					})
				} else {
					if (this.values.filter(v => v.id == 4).length > 0) {
						this.$router.push('/finish-register')
					} else {
						document.location.href = `app://form-finish?token=${this.token}`;
					}
				}
			},
			finish() {
				if (this.values.filter(v => v.id == 4).length > 0) {
					this.$router.push('/finish-register')
				} else {
					document.location.href = 'app://form-finish';
				}
			}
		}
	}
</script>

<style>
	.h-100 {
		height: 100%;
	}
	.button__registration {
		bottom: 60px !important;
	}
	.loader {
		width: 100vw;
		height: 100vh;
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10000;
		background-color: rgba(0,0,0,0.5);
		backdrop-filter: blur(2px);
	}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.header--empty {
	background: none !important;
	margin-bottom: 30px !important;
}
.header--empty:before {
	display: none !important;
}
.header--empty:after {
	display: none !important;
}

.header__back--register {
	margin-top: -10px !important;
}

.registration__form input {
	font-size: 20px !important;
}
.registration__form input::-webkit-placeholder {font-size: 20px !important;}
.registration__form input::-moz-placeholder {font-size: 20px !important;}
.registration__form input::-ms-placeholder {font-size: 20px !important;}
.registration__form input::placeholder {font-size: 20px !important;}

.button__continue-black--reg {bottom: 25px !important;}

</style>