<template>
  <div class="wrapper wrapper-second">
    <Progress @back="goToStep(1)" />
    <QuestionFields :name="name" error="Нужно ввести рост и вес" @submit="setValue">
      <template v-slot:Title>Твой рост и вес:</template>
    </QuestionFields>
  </div>
</template>

<script>
  import QuestionFields from '@/components/QuestionFields'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_2',
    data() {
      return {
        name: 'age',
      }
    },
    components: {
      Progress,
      QuestionFields,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let heightValue = {
          id: 2,
          title: 'Твой рост',
          answerText: value.height,
          answer: value.height,
        }
        let weightValue = {
          id: 3,
          title: 'Твой вес',
          answerText: value.weight,
          answer: value.weight,
        }
        this.$store.dispatch('setValue', heightValue);
        this.$store.dispatch('setValue', weightValue);
        this.$store.dispatch('setStep', 3);
        this.$router.push({ path: '/', query: {step: 3}});
      }
    }
  }
</script>

<style>
  .wrapper-second::after {
    position:absolute;
    width:0;
    height:0;
    overflow:hidden;
    z-index:-1;
    content: url(../assets/img/distanse_background.jpg) url(../assets/img/experience_background.jpg) url(../assets/img/finish-background.jpg) url(../assets/img/first_background.jpg) url(../assets/img/fourth_background.jpg) url(../assets/img/gender_background.jpg) url(../assets/img/program-background.jpg) url(../assets/img/program-background-2.jpg) url(../assets/img/program-background-3.jpg) url(../assets/img/program-background-4.jpg) url(../assets/img/program-background-5.jpg) url(../assets/img/races-background.jpg) url(../assets/img/result-background.jpg) url(../assets/img/running-date_background.jpg) url(../assets/img/running-known_background.jpg);
  }
</style>
