<template>
  <div class="wrapper wrapper-third">
    <Progress @back="goToStep(2)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue" @setLs="setLs" :id="4">
      <template v-slot:Title>Твой беговой опыт:</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_3',
    data() {
      return {
        name: 'experience',
        variants: [
          {
            key: 1,
            title: 'Не бегал никогда'
          },
          {
            key: 3,
            title: 'Бегал больше 3 лет назад'
          },
          {
            key: 2,
            title: 'Регулярно бегал 1-2 года назад'
          },
          {
            key: 4,
            title: 'Бегаю последние несколько лет'
          },
          {
            key: 5,
            title: 'Бегаю всю жизнь'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 4,
          title: 'Твой беговой опыт',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 4);
        this.$router.push({ path: '/', query: {step: 4}});
      },
      setLs(value) {
        let stepData = {
          id: 4,
          title: 'Твой беговой опыт',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
