<template>
	<main class="content">
		<div class="content__wrapper">
			<h1 class="content__title" v-bind:class="{'content__title--white': whiteTitle}"><slot name="Title"></slot></h1>
			<div class="qwiz-area">
				<div class="content__item" v-for="v in variants" v-bind:key="v.key">
					<label class="content__item--radio" v-bind:class="{'content__item--radio-white': whiteTitle}">
					<input type="radio" name="age" @change="$emit('setLs', value)" :value="v.key" v-model.trim="$v.value.$model">
					<span>{{v.title}}</span>
					</label>
				</div>
			</div>
		</div>
		<a href="javascript:void(0)" @click="next()" class="button">Продолжить</a>
		</main>
</template>

<script>
	import Vue from 'vue'
	import { required } from 'vuelidate/lib/validators'
	export default {
		name: 'QuestionRadio',
		data() {
			return {
				value: null,
			}
		},
		props: {
			variants: Array,
			name: String,
			error: String,
			whiteTitle: Boolean,
			id: Number,
		},
		validations: {
			value: {
				required,
			},
		},
    computed: {
      values() {
        return this.$store.state.values;
      },
    },
    mounted() {
      if (this.values.filter(v => v.id == this.id).length > 0) {
        this.value = this.values.filter(v => v.id == this.id)[0].answer;
      }
    },
		methods: {
			next() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					Vue.$toast.open({
						message: this.error,
						type: 'error',
						position: 'bottom'
					});
				} else {
					this.$emit('submit', this.value);
				}
			}
		}
	}
</script>