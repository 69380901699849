<template>
  <div class="wrapper">
    <Progress @back="goToStep(0)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать возраст" @submit="setValue" @setLs="setLs" :id="1">
      <template v-slot:Title>Твой возраст:</template>
    </QuestionRadio>
    <Skip />
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  import Skip from '@/components/Skip'
  export default {
    name: 'Step_1',
    data() {
      return {
        name: 'age',
        variants: [
          {
            key: 1,
            title: 'До 20 лет'
          },
          {
            key: 2,
            title: '20 - 35 лет'
          },
          {
            key: 3,
            title: '35 - 50 лет'
          },
          {
            key: 4,
            title: '50 - 60 лет'
          },
          {
            key: 5,
            title: 'Старше 60 лет'
          },
        ]
      }
    },
    components: {
      Progress,
      Skip,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 1,
          title: 'Твой возраст',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 2);
        this.$router.push({ path: '/', query: {step: 2}});
      },
      setLs(value) {
        let stepData = {
          id: 1,
          title: 'Твой возраст',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
