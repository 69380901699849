<template>
	<footer class="footer">
    <button class="button__continue" @click="skip">Пропустить</button>
  </footer>
</template>

<script>
	export default {
		methods: {
			skip() {
				this.$router.push('/register')
			}
		}
	}
</script>

<style>
	.button__continue {
		bottom: 60px !important;
	}
</style>