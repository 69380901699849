<template>
  <div class="wrapper wrapper-result">
    <Progress @back="goToStep(6.2)" />
    <QuestionRadio :variants="variants" :name="name" error="Выбери один из вариантов" @submit="setValue" @setLs="setLs" :id="6.3">
      <template v-slot:Title>Желаемый результат</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_6-3',
    data() {
      return {
        name: 'result',
        variants: [
          {
            key: 1,
            title: 'Из 4 часов'
          },
          {
            key: 2,
            title: 'Из 3-45'
          },
          {
            key: 3,
            title: 'Из 3-30'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 6.3,
          title: 'Желаемый результат',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 7);
        this.$router.push({ path: '/', query: {step: 7}});
      },
      setLs(value) {
        let stepData = {
          id: 6.3,
          title: 'Желаемый результат',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
