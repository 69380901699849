<template>
	<div class="h-100">
		<div class="scrolled" v-if="values.filter(v => v.id == 4)[0].answer < 4">
			<header class="choose-program__header"></header>
			<main>
				<div class="choose-program__content">
					<h1 class="choose-program__title">Твоя рекомендуемая программа <span></span></h1>
					<h2 class="choose-program__subtitle">БЕГ С НУЛЯ!</h2>
					<p class="choose-program__text">Научим бегать правильно и в удовольствие. <br>
						Уже через 3,5 месяца ты свободно сможешь преодолеть дистанцию в
						10 километров
					</p>
					<a href="javascript:void(0)" class="button button__registration button--static button--full" @click="goToApp">Выбрать эту программу</a>
				</div>
			</main>
			<footer class="footer__links">
				<button class="button__continue button__continue-black button--static button--auto">Пропустить</button>
				<a href="#" class="footer__link">Посмотреть все программы</a>
			</footer>
		</div>
		<div class="scrolled" v-if="values.filter(v => v.id == 4)[0].answer > 3 && values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 0">
			<header class="choose-program__header choose-program__header--2"></header>
			<main>
				<div class="choose-program__content">
					<h1 class="choose-program__title">Твоя рекомендуемая программа <span></span></h1>
					<h2 class="choose-program__subtitle">БЕГ: РАЗВИТИЕ</h2>
					<p class="choose-program__text">Простое и эффективное развитие твоей беговой формы. Регулярные и правильные тренировки помогут привести организм в порядок, укрепить здоровье и сбросить лишний вес
					</p>
					<a href="#" class="button button__registration button--static button--full">Выбрать эту программу</a>
				</div>
			</main>
			<footer class="footer__links">
				<button class="button__continue button__continue-black button--static button--auto">Пропустить</button>
				<a href="#" class="footer__link">Посмотреть все программы</a>
			</footer>
		</div>
		<div class="scrolled" v-if="values.filter(v => v.id == 4)[0].answer > 3 && values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && values.filter(v => v.id == 6)[0].answer == 1">
			<header class="choose-program__header choose-program__header--3"></header>
			<main>
				<div class="choose-program__content">
					<h1 class="choose-program__title">Твоя рекомендуемая программа <span></span></h1>
					<h2 class="choose-program__subtitle">МОЙ ПЕРВЫЙ ПОЛУМАРАФОН</h2>
					<p class="choose-program__text">Индивидуальная программа под дату твоего старта, включает в себя необходимые рекомендации для подготовки к полумарафону и сбалансированные нагрузки под уровень твоей текущей подготовки
					</p>
					<a href="javascript:void(0)" class="button button__registration button--static button--full" @click="goToApp">Выбрать эту программу</a>
				</div>
			</main>
			<footer class="footer__links">
				<button class="button__continue button__continue-black button--static button--auto">Пропустить</button>
				<a href="#" class="footer__link">Посмотреть все программы</a>
			</footer>
		</div>
		<div class="scrolled" v-if="values.filter(v => v.id == 4)[0].answer > 3 && values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && values.filter(v => v.id == 6)[0].answer == 2 && values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 6.2).length < 1">
			<header class="choose-program__header choose-program__header--4"></header>
			<main>
				<div class="choose-program__content">
					<h1 class="choose-program__title">Твоя рекомендуемая программа <span></span></h1>
					<h2 class="choose-program__subtitle">МОЙ ПЕРВЫЙ МАРАФОН</h2>
					<p class="choose-program__text">Эффективная подготовка к запланированному марафону. Программа учитывает уровень твоей начальной подготовки, включает начальные недели адаптации и построена на основе твоих пульсовых зон.
					</p>
					<a href="javascript:void(0)" class="button button__registration button--static button--full" @click="goToApp">Выбрать эту программу</a>
				</div>
			</main>
			<footer class="footer__links">
				<button class="button__continue button__continue-black button--static button--auto">Пропустить</button>
				<a href="#" class="footer__link">Посмотреть все программы</a>
			</footer>
		</div>
		<div class="scrolled" v-if="values.filter(v => v.id == 4)[0].answer > 4 && values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && values.filter(v => v.id == 6)[0].answer == 2 && values.filter(v => v.id == 7)[0].answer == 3 && values.filter(v => v.id == 8)[0].answer == 2 &&  values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 6.2).length == 1">
			<header class="choose-program__header choose-program__header--4"></header>
			<main>
				<div class="choose-program__content">
					<h1 class="choose-program__title">Твоя рекомендуемая программа <span></span></h1>
					<h2 class="choose-program__subtitle">МОЙ БЫСТРЫЙ МАРАФОН</h2>
					<p class="choose-program__text">Программа рассчитана для бегунов продвинутого уровня, тех кто уже участвовал в забегах и хочет подготовиться к быстрому преодолению марафона
					</p>
					<a href="javascript:void(0)" class="button button__registration button--static button--full" @click="goToApp">Выбрать эту программу</a>
				</div>
			</main>
			<footer class="footer__links">
				<button class="button__continue button__continue-black button--static button--auto">Пропустить</button>
				<a href="#" class="footer__link">Посмотреть все программы</a>
			</footer>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'finish',
		data() {
			return {
				password: false,
			}
		},
		computed: {
			values() {
				return this.$store.state.values;
			}
		},
		methods: {
			goToApp() {
				window.location.href = 'app://form-finish';
			}
		},
	}
</script>

<style>
	.h-100 {
		height: 100%;
	}
	.choose-program__header--2 {
		background-image: url(../assets/img/program-background-2.jpg);
	}
	.choose-program__header--3 {
		background-image: url(../assets/img/program-background-3.jpg);
	}
	.choose-program__header--4 {
		background-image: url(../assets/img/program-background-4.jpg);
	}
</style>