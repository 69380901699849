<template>
	<router-view/>
</template>

<style>
	body::after {
		position:absolute;
		width:0;
		height:0;
		overflow:hidden;
		z-index:-1;
		content: url(./assets/img/return.svg) url(./assets/img/first_background.jpg) url(./assets/img/second_background.jpg) url(./assets/img/third_background.jpg)
	}
</style>

<script>
	export default {
		methods: {
			appHeight() {
				const doc = document.documentElement;
				doc.style.setProperty('--app-height', `${window.innerHeight}px`);
			}
		},
		computed: {
			values() {
				return this.$store.state.values;
			}
		},
		mounted() {
			window.addEventListener('resize', this.appHeight)
			this.appHeight();
			//this.$store.dispatch('getVersion')
		}
	}
</script>

<style>
	.content__wrapper {
		margin-bottom: 12px !important;
	}
	.wrapper {
		padding-top: 65px !important;
		-webkit-background-size: cover !important;
		background-size: cover !important;
		background-position: 50% 50% !important;
	}
</style>