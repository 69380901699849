<template>
	<main class="content">
		<div class="content__wrapper">
			<h1 class="content__title" v-bind:class="{'content__title--white': whiteTitle}"><slot name="Title"></slot></h1>
			<div class="qwiz-area">
				<div class="content__item" v-for="v in variants" v-bind:key="v.key">
					<label class="content__item--checkbox" v-bind:class="{'text-dark': black}">
						<input type="checkbox" @change="setValue(v.key, v.title)" :value="v.key" name="checkbox" v-if="values.filter(val => val.id == v.key).length == 0">
						<input type="checkbox" @change="removeValue(v.key, v.title)" :value="v.key" name="checkbox" checked v-else>
						<span>{{v.title}}</span>
					</label>
				</div>
			</div>
		</div>
		<a href="javascript:void(0)" @click="next()" class="button">Продолжить</a>
		</main>
</template>

<script>
	import Vue from 'vue'
	import { required } from 'vuelidate/lib/validators'
	export default {
		name: 'QuestionCheckbox',
		data() {
			return {
				value: null,
				values: [],
			}
		},
		props: {
			variants: Array,
			name: String,
			error: String,
			whiteTitle: Boolean,
			black: Boolean,
			id: Number,
		},
		validations: {
			values: {
				required,
			},
		},
    computed: {
      all_values() {
        return this.$store.state.values;
      },
    },
    mounted() {
      if (this.all_values.filter(v => v.id == this.id).length > 0) {
        this.values = this.all_values.filter(v => v.id == this.id)[0].answer;
      }
    },
		methods: {
			setValue(id, title) {
				this.values.push({
					id: id,
					title: title,
				});
				this.$emit('setLs', this.values);
			},
			removeValue(id) {
				let index = this.values.findIndex(v => v.id === id);
				this.values.splice(index, 1);
				this.$emit('setLs', this.values);
			},
			next() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					Vue.$toast.open({
						message: this.error,
						type: 'error',
						position: 'bottom'
					});
				} else {
					this.$emit('submit', this.values);
				}
			}
		}
	}
</script>

<style>
	.text-dark span {
		text-shadow: none !important;
		color: #000 !important;
	}
</style>