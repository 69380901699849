<template>
  <div class="wrapper wrapper-running-known">
    <Progress @back="goToStep(6)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue" @setLs="setLs" :id="8">
      <template v-slot:Title>Знаешь ли дату своего забега?</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'date',
        variants: [
          {
            key: 1,
            title: 'Да'
          },
          {
            key: 2,
            title: 'Нет'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 8,
          title: 'Знаешь ли дату своего забега?',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        if (value == 2) {
          this.$router.push('/register');
        } else {
          this.$store.dispatch('setStep', 8);
          this.$router.push({ path: '/', query: {step: 8}});
        }
      },
      setLs(value) {
        let stepData = {
          id: 8,
          title: 'Знаешь ли дату своего забега?',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
