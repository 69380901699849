<template>
  <div class="wrapper wrapper-gender">
    <Progress :isBack="true" @back="back" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать пол" @submit="setValue" @setLs="setLs" :id="0">
      <template v-slot:Title>Добро пожаловать! <br>Я...</template>
    </QuestionRadio>
    <Skip />
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  import Skip from '@/components/Skip'
  export default {
    name: 'Step_3',
    data() {
      return {
        name: 'experience',
        variants: [
          {
            key: 1,
            title: 'Мужчина'
          },
          {
            key: 2,
            title: 'Женщина'
          },
        ]
      }
    },
    components: {
      Progress,
      Skip,
      QuestionRadio,
    },
    methods: {
      setValue(value) {
        let stepData = {
          id: 0,
          title: 'Твой пол',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 1);
        this.$router.push({ path: '/', query: {step: 1}});
      },
      setLs(value) {
        let stepData = {
          id: 0,
          title: 'Твой пол',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      },
      back() {
        document.location.href = 'app://back-to-app';
      }
    }
  }
</script>
