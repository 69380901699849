<template>
  <div class="wrapper wrapper-distance">
    <Progress @back="goToStep(4)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue" @setLs="setLs" :id="6">
      <template v-slot:Title>К какой дистанции готовишься?</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'distance',
        variants: [
          {
            key: 1,
            title: '21 км'
          },
          {
            key: 2,
            title: '42 км'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      },
      values() {
        return this.$store.state.values;
      },
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let exp = this.values.filter(v => v.id === 4)[0].answer;
        let stepData = {
          id: 6,
          title: 'К какой дистанции готовишься',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        if (exp > 3) {
          this.$store.dispatch('setStep', 6);
          this.$router.push({ path: '/', query: {step: 6}});
        } else {
          if (this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1) {
            this.$store.dispatch('setStep', 7);
            this.$router.push({ path: '/', query: {step: 7}});
          } else {
            this.$router.push({ path: '/register'});
          }
        }
      },
      setLs(value) {
        let stepData = {
          id: 6,
          title: 'К какой дистанции готовишься',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
