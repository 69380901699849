<template>
	<header class="header">
		<div class="header__back" @click="$emit('back')" v-if="isBack"></div>
		<div class="header__fill" :style="`width: ${width}%`"></div>
	</header>
</template>

<script>
	export default {
		name: 'Progress',
		props: {
			isBack: {
				type: Boolean,
				default: true,
				required: false
			},
		},
		computed: {
			step() {
				return this.$store.state.step;
			},
			width() {
				return this.step * 10;
			},
		},
	}
</script>


<style>
	.header__back {
		width: 25px;
		height: 25px;
		background-image: url(../assets/img/return.svg);
		position: fixed;
		left: 20px;
		margin-top: -25px;
		cursor: pointer;
	}
</style>