<template>
	<div class="h-100">
		<Step_0 v-if="step == 0" />
		<Step_1 v-if="step == 1" />
		<Step_2 v-if="step == 2" />
		<Step_3 v-if="step == 3" />
		<Step_4 v-if="step == 4" />
		<Step_5 v-if="step == 5" />
		<Step_6 v-if="step == 6" />
		<Step_6_1 v-if="step == '6.1'" />
		<Step_6_2 v-if="step == '6.2'" />
		<Step_6_3 v-if="step == '6.3'" />
		<Step_7 v-if="step == 7" />
		<Step_8 v-if="step == 8" />
	</div>
</template>

<script>
	import Step_0 from '@/components/Step_0';
	import Step_1 from '@/components/Step_1';
	import Step_2 from '@/components/Step_2';
	import Step_3 from '@/components/Step_3';
	import Step_4 from '@/components/Step_4';
	import Step_5 from '@/components/Step_5';
	import Step_6 from '@/components/Step_6';
	import Step_6_1 from '@/components/Step_6-1';
	import Step_6_2 from '@/components/Step_6-2';
	import Step_6_3 from '@/components/Step_6-3';
	import Step_7 from '@/components/Step_7';
	import Step_8 from '@/components/Step_8';
	export default {
		name: 'Quiz',
		computed: {
			step() {
				return this.$store.state.step;
			}
		},
		components: {
			Step_0,
			Step_1,
			Step_2,
			Step_3,
			Step_4,
			Step_5,
			Step_6,
			Step_6_1,
			Step_6_2,
			Step_6_3,
			Step_7,
			Step_8,
		},
		mounted() {
			if (this.$route.query.token) {
				this.$store.dispatch('setToken', this.$route.query.token);
			}
			if (this.$route.query.gender && this.$route.query.gender == 'men' || this.$route.query.gender == 'women') {
				let value = 1;
				if (this.$route.query.gender == 'women') {
					value = 2;
				}
				if (!this.$route.query.step) {
					this.$store.dispatch('setStep', 0);
					this.$store.dispatch('setStep', 1);
					this.$router.push({ path: '/', query: {step: 1}});
				} else {
					this.$store.dispatch('setStep', this.$route.query.step);
					this.$router.push({ path: '/', query: {step: this.$route.query.step}});
				}
				let stepData = {
					id: 0,
					title: 'Твой пол',
					answerText: value === 1 ? 'Мужчина' : 'Женщина',
					answer: value,
				}
				this.$store.dispatch('setValue', stepData);
			} else {
				if (!this.$route.query.step) {
					this.$store.dispatch('setStep', 0);
					this.$router.push({ path: '/', query: {step: 0}});
				} else {
					this.$store.dispatch('setStep', this.$route.query.step);
					this.$router.push({ path: '/', query: {step: this.$route.query.step}});
				}
			}
		},
		watch: {
			$route(to) {
				if (to.query.step) {
					this.$store.dispatch('setStep', to.query.step);
				}
			}
		}
	}
</script>

<style>
	.h-100 {
		height: 100%;
	}
</style>