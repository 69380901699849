<template>
  <div class="wrapper wrapper-experience">
    <Progress @back="goToStep(4)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue" @setLs="setLs" :id="7">
      <template v-slot:Title>Твой беговой стаж <br>за последний год:</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'distance',
        variants: [
          {
            key: 1,
            title: 'Регулярно бегаю от 1 до 3 мес'
          },
          {
            key: 2,
            title: 'Регулярно бегаю от 3 до 6 мес'
          },
          {
            key: 3,
            title: 'Регулярно бегаю год и больше'
          },
        ]
      }
    },
    computed: {
      values() {
        return this.$store.state.values;
      },
      step() {
        return this.$store.state.step;
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 7,
          title: 'Твой беговой стаж за последний год',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        if (this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && this.values.filter(v => v.id == 4)[0].answer > 3) {
          this.$store.dispatch('setStep', '6.1');
          this.$router.push({ path: '/', query: {step: '6.1'}});
        } else {
          if (this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1) {
            this.$store.dispatch('setStep', 7);
            this.$router.push({ path: '/', query: {step: 7}});
          } else {
            this.$router.push({ path: '/register'});
          }
        }
      },
      setLs(value) {
        let stepData = {
          id: 7,
          title: 'Твой беговой стаж за последний год',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
