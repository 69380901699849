<template>
  <div class="wrapper wrapper-races">
    <Progress @back="goToStep(6)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue" @setLs="setLs" :whiteTitle="true" :id="6.1">
      <template v-slot:Title>Принимал ли участие в забегах за последние 6 мес.?</template>
    </QuestionRadio>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'distance',
        variants: [
          {
            key: 1,
            title: 'Нет'
          },
          {
            key: 2,
            title: 'Да'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 6.1,
          title: 'Принимал ли участие в забегах за последние 6 мес.?',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData).then(() => {
          if (value == 1) {
            this.$store.dispatch('setStep', 7);
            this.$router.push({ path: '/', query: {step: 7}});
          } else {
            this.$store.dispatch('setStep', '6.2');
            this.$router.push({ path: '/', query: {step: '6.2'}});
          }
        })
      },
      setLs(value) {
        let stepData = {
          id: 6.1,
          title: 'Принимал ли участие в забегах за последние 6 мес.?',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
