<template>
  <div class="wrapper wrapper-finish">
    <Progress @back="goToStep(6.1)" />
    <QuestionCheckbox :variants="variants" :name="name" error="Нужно выбрать один или несколько вариантов" @submit="setValue" @setLs="setLs" :id="6.2" :black="true">
      <template v-slot:Title>Какие дистанции финишировал?</template>
    </QuestionCheckbox>
  </div>
</template>

<script>
  import QuestionCheckbox from '@/components/QuestionCheckbox'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'distance',
        variants: [
          {
            key: 1,
            title: 'Паркраны, забег на короткие дистанции 1-5км'
          },
          {
            key: 2,
            title: 'Финишировал 10км'
          },
          {
            key: 3,
            title: 'Финишировал 21км'
          },
          {
            key: 4,
            title: 'Финишировал 42,2 км'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionCheckbox,
    },
    computed: {
      values() {
        return this.$store.state.values;
      },
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 6.2,
          title: 'Какие дистанции финишировал?',
          answerText: value,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData).then(() => {
          if (this.values.filter(v => v.id == 4)[0].answer > 3 && this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1 && this.values.filter(v => v.id == 6)[0].answer == 2 && this.values.filter(v => v.id == 7)[0].answer == 3 && this.values.filter(v => v.id == 6.2)[0].answer.filter(a => a.id == 4).length == 1) {
            this.$store.dispatch('setStep', '6.3');
            this.$router.push({ path: '/', query: {step: '6.3'}});
          } else {
            if (this.values.filter(v => v.id == 5)[0].answer.filter(a => a.id == 5).length == 1) {
            this.$store.dispatch('setStep', 7);
            this.$router.push({ path: '/', query: {step: 7}});
            } else {
              this.$router.push({ path: '/register'});
            }
          }
        })
      },
      setLs(value) {
        let stepData = {
          id: 6.2,
          title: 'Какие дистанции финишировал?',
          answerText: value,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
