<template>
	<main class="content">
		<div class="content__wrapper">
			<h1 class="content__title"><slot name="Title"></slot></h1>
			<div class="qwiz-area">
				<div class="content__sizes">
					<div class="content__field--wrapper">
						<input type="number" class="content--size__field" v-model.number="$v.field_1.$model" pattern="\d*" inputmode="numeric">
					</div>
					<div class="content__field--wrapper">
						<input type="number" class="content--size__field" v-model.number="$v.field_2.$model" pattern="\d*" inputmode="numeric">
					</div>
				</div>
			</div>
		</div>
		<a href="javascript:void(0)" @click="next()" class="button">Продолжить</a>
	</main>
</template>

<script>
	import Vue from 'vue'
	import { required, minLength } from 'vuelidate/lib/validators'
	export default {
		name: 'QuestionRadio',
		data() {
			return {
				field_1: '',
				field_2: '',
			}
		},
		props: {
			name: String,
			error: String,
		},
		validations: {
			field_1: {
				required,
				minLength: minLength(2)
			},
			field_2: {
				required,
				minLength: minLength(2)
			},
		},
    computed: {
      values() {
        return this.$store.state.values;
      },
    },
    mounted() {
      if (this.values.filter(v => v.id == 2).length > 0) {
        this.field_1 = this.values.filter(v => v.id == 2)[0].answer;
      }
      if (this.values.filter(v => v.id == 3).length > 0) {
        this.field_2 = this.values.filter(v => v.id == 3)[0].answer;
      }
    },
		methods: {
			next() {
				this.$v.$touch()
				if (this.$v.$invalid) {
					Vue.$toast.open({
						message: this.error,
						type: 'error',
						position: 'bottom'
					});
				} else {
					let data = {
						weight: this.field_2,
						height: this.field_1,
					}
					this.$emit('submit', data);
				}
			}
		},
		watch: {
			field_1(val, oldVal) {
				if (val.length > 3) {
						this.field_1 = oldVal
				}
			}
		}
	}
</script>

<style>
	.content--size__field {
		padding-right: 68px;
	}
</style>