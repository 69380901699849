<template>
  <div class="wrapper wrapper-fourth">
    <Progress @back="goToStep(3)" />
    <QuestionCheckbox :variants="variants" :name="name" error="Нужно выбрать одну или несколько целей" @submit="setValue" @setLs="setLs" :whiteTitle="true" :id="5">
      <template v-slot:Title>Твоя беговая цель:</template>
    </QuestionCheckbox>
  </div>
</template>

<script>
  import QuestionCheckbox from '@/components/QuestionCheckbox'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_4',
    data() {
      return {
        name: 'experience',
        variants: [
          {
            key: 1,
            title: 'Быть подтянутым, в хорошей форме'
          },
          {
            key: 2,
            title: 'Поддерживать активный образ жизни'
          },
          {
            key: 3,
            title: 'Укрепление здоровья'
          },
          {
            key: 4,
            title: 'Нормализация веса'
          },
          {
            key: 5,
            title: 'Подготовка к дистанции'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionCheckbox,
    },
    computed: {
      values() {
        return this.$store.state.values;
      },
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let dist = value.filter(v => v.id === 5).length;
        let exp = this.values.filter(v => v.id === 4)[0].answer;
        let stepData = {
          id: 5,
          title: 'Твоя беговая цель',
          answerText: value,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        if (dist == 1) {
          this.$store.dispatch('setStep', 5);
          this.$router.push({ path: '/', query: {step: 5}});
        } else {
          if (exp > 3) {
            this.$store.dispatch('setStep', 6);
            this.$router.push({ path: '/', query: {step: 6}});
          } else {
            this.$router.push('/register');
          }
        }
      },
      setLs(value) {
        let stepData = {
          id: 5,
          title: 'Твоя беговая цель',
          answerText: value,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
